import { PageProps } from 'gatsby'

import { FC } from 'react'

import { withPage } from '~/components/page'
import AddLeadView from '~/views/addLead'

interface Props extends PageProps {
	params: {
		id: string
	}
}

const LeadAddPage: FC<Props> = () => {
	return <AddLeadView />
}

export default withPage(LeadAddPage, {
	public: false,
	name: 'Add Lead',
})
